import { useCallback } from 'react';

import { useAddPlayersToGame } from 'pages/backoffice/api/games/use-add-players-to-game';
import { useCreateMetadataGame } from 'pages/backoffice/api/games/use-create-metadata-game';
import { useDeleteMetadataGame } from 'pages/backoffice/api/games/use-delete-metadata-game';
import { GameInfoSchema } from 'pages/backoffice/pages/games/game-form/forms';
import { NewGameTeam } from 'pages/backoffice/pages/games/game-form/types';
import { MetadataGame } from 'pages/backoffice/types/games';
import { MatchEndType } from 'pages/backoffice/types/shared';

export const useCreateNewGameWithPlayers = (onSuccess: (game: MetadataGame) => void) => {
  const { create } = useCreateMetadataGame();
  const { deleteMetadataGame } = useDeleteMetadataGame({ successMessage: undefined });
  const { create: addPlayersToGame } = useAddPlayersToGame({ successMessage: 'Game created' });

  return useCallback(
    (gameInfo: GameInfoSchema, homeTeam: NewGameTeam, awayTeam: NewGameTeam) => {
      create({
        data: {
          name: gameInfo.name,
          duration: gameInfo.duration,
          home_team_score: gameInfo.scoreHomeTeam,
          away_team_score: gameInfo.scoreOpponentTeam,
          pitch_length: gameInfo.pitchLength,
          pitch_width: gameInfo.pitchWidth,
          home_penalty_score: 0,
          away_penalty_score: 0,
          match_end_type: MatchEndType.NORMAL_TIME,
          home_team_uuid: homeTeam?.team.id ?? '',
          away_team_uuid: awayTeam?.team.id ?? '',
          stage_uuid: gameInfo.stageId,
          ...(gameInfo?.homeCoach?.id ? { home_coach_uuid: gameInfo.homeCoach.id } : {}),
          ...(gameInfo?.awayCoach?.id ? { away_coach_uuid: gameInfo.awayCoach.id } : {}),
          ...(gameInfo.venue ? { venue_uuid: gameInfo.venue.id } : {}),
          ...(gameInfo.date ? { date: gameInfo.date } : {}),
        },
        onSuccess: (game: MetadataGame) => {
          addPlayersToGame({
            onSuccess: () => {
              onSuccess(game);
            },
            onError: () => {
              deleteMetadataGame({ id: game.id });
            },
            gameId: game.id,
            players: [
              ...homeTeam.players.map((p) => ({
                jersey_number: p.jerseyNumber ?? 0,
                side: p.side,
                position: p.position,
                player_uuid: p.id,
                team_uuid: homeTeam.team.id,
              })),
              ...awayTeam.players.map((p) => ({
                jersey_number: p.jerseyNumber ?? 0,
                side: p.side,
                position: p.position,
                player_uuid: p.id,
                team_uuid: awayTeam.team.id,
              })),
            ],
          });
        },
      });
    },
    [deleteMetadataGame, addPlayersToGame, create, onSuccess],
  );
};
