import { Box, Button, CardContent, Stack, TextField, Typography } from '@mui/material';
import { fontSizes } from 'kognia-ui';
import debounce from 'lodash/debounce';
import { ChangeEvent, useCallback } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

import { routes } from 'kognia/router/routes';
import { useMetadataGames } from 'pages/backoffice/api/games/use-metadata-games';
import { BackofficeContentContainer } from 'pages/backoffice/components/backoffice-content-container';
import { ListSkeleton } from 'pages/backoffice/components/list-skeleton';
import { GamesList } from 'pages/backoffice/pages/games/components/metadata-game-list';
import { useResetGameFormData } from 'pages/backoffice/pages/games/game-form/config';
import { GameListHeaderGrid } from 'pages/backoffice/pages/games/styled';
import IconPlus from 'shared/components/icons/icon-plus';
import { ListHeader } from 'shared/components/list/list-header';
import NotFound from 'shared/components/not-found';
import Pagination from 'shared/components/pagination';

const DEBOUNCE_TIME = 300;

export const Games = () => {
  const navigate = useNavigate();
  const resetFormData = useResetGameFormData();
  const { items, page, fetchNextPage, isFetching, setFilters } = useMetadataGames();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearchChange = useCallback(
    debounce((event: ChangeEvent<HTMLInputElement>) => {
      setFilters({ name: event.target.value });
    }, DEBOUNCE_TIME),
    [setFilters],
  );

  return (
    <BackofficeContentContainer>
      <Stack direction='row' alignItems='center' justifyContent='space-between' marginBottom={2}>
        <Typography variant='h4'>Games</Typography>
        <Button
          variant='contained'
          size='large'
          startIcon={<IconPlus size='small' sx={{ color: 'common.white' }} />}
          onClick={() => {
            resetFormData();
            navigate(generatePath(routes.BACKOFFICE_GAME_FORM_STEP_1, { id: null }));
          }}
        >
          New game
        </Button>
      </Stack>

      <CardContent>
        <>
          <Stack marginBottom={2} justifyContent='flex-end' direction='row'>
            <TextField size='small' placeholder='Search games' onChange={handleSearchChange} />
          </Stack>

          <ListHeader>
            <GameListHeaderGrid>
              <Typography variant='body1' fontSize={fontSizes.default} fontWeight={'fontWeightMedium'}>
                Score
              </Typography>
              <Typography variant='body1' fontSize={fontSizes.default} fontWeight={'fontWeightMedium'}>
                Teams
              </Typography>
              <Typography variant='body1' fontSize={fontSizes.default} fontWeight={'fontWeightMedium'}>
                Event
              </Typography>
              <div>&nbsp;</div>
            </GameListHeaderGrid>
          </ListHeader>
          {!isFetching && items.length === 0 ? (
            <Box marginTop={4}>
              <NotFound header={'No results'} />
            </Box>
          ) : null}

          {isFetching && !Boolean(items.length > 0) ? <ListSkeleton /> : <GamesList games={items} />}

          {page ? (
            <Pagination
              total={page.totalElements}
              displayed={items.length}
              onShowMore={fetchNextPage}
              getStatsText={(displayed, total) => `${displayed} of ${total} games`}
            />
          ) : null}
        </>
      </CardContent>
    </BackofficeContentContainer>
  );
};
