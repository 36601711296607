import { useInfiniteQuery } from '@tanstack/react-query';
import isEmpty from 'lodash/isEmpty';
import React, { ReactNode, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { queryClient } from 'api/config';
import { useBackendApi } from 'api/hooks/useBackendApi';
import { transformRecordings } from 'api/recording/transformers';
import { transformFiltersForRequest } from 'api/recording/useFetchRecordings/util/transform-filters-for-request';
import { recordingsListUrl } from 'api/routes';
import { fetchQueryResponse, HTTPMethod } from 'api/types';
import { useClientId } from 'shared/contexts/app-state/hooks/useClientId';
import { FiltersList } from 'shared/types/filters/types';
import { Recording } from 'shared/types/recording/types';

export const generateFetchRecordingsQueryRef = (clientId: string) => `fetchRecordings-clientId:${clientId}`;

export type FetchRecordingsResponse = fetchQueryResponse<Recording[]> & {
  fetchNextPage: () => void;
  totalElements: number;
  isFetchingNextPage: boolean;
  recordingsFilters: FiltersList;
  filters: FiltersList;
  setRecordingsFilters: (options: FiltersList) => void;
  RecordingsStateContext: ({ children }: { children: ReactNode }) => JSX.Element;
};

interface UseRecordingsOptions {
  extraKey?: string;
  initialFilters?: FiltersList;
}

export interface useFetchRecordingsInterface {
  (options: UseRecordingsOptions): FetchRecordingsResponse;
}

export interface RecordingEndpointFilters {
  annotationType?: string;
  competition?: string;
  date?: string;
  matchday?: string;
  page: number;
  size?: number;
  sort?: string;
  teamId?: string;
  type?: string;
}

const RecordingsStateContext = React.createContext<{ filters: FiltersList } | undefined>(undefined);

const PAGE_SIZE = 12;
const SORT = 'date,desc';

const useRecordings: useFetchRecordingsInterface = ({ extraKey = '', initialFilters = {} }) => {
  const [filters, setFilters] = useState(initialFilters);
  const location = useLocation();
  const navigate = useNavigate();

  const { clientId } = useClientId();

  const fetchQueryRef = generateFetchRecordingsQueryRef(`${clientId}${extraKey}`);

  const value = { filters };

  const queryRef = useMemo(() => [fetchQueryRef, filters], [fetchQueryRef, filters]);

  const { isError, isSuccess, data, isFetching, isPending, fetchNextPage, isFetchingNextPage } = useInfiniteQuery({
    queryKey: queryRef,
    queryFn: ({ pageParam }) => {
      // TODO: do it with, navigate is temporary solution to have
      // TODO: history - setSearchParams(transformFiltersForRequest(filters).search);
      navigate(`${location.pathname}?${transformFiltersForRequest(filters).search}`, { replace: true });
      return useBackendApi(
        recordingsListUrl({
          ...transformFiltersForRequest(filters).filters,
          size: PAGE_SIZE,
          sort: SORT,
          page: pageParam,
        }),
        HTTPMethod.GET,
        transformRecordings,
      );
    },
    getNextPageParam: (lastPage: { nextCursor: number }) => {
      return lastPage.nextCursor;
    },
    refetchInterval: 60000,
    initialPageParam: 0,
  });

  const setQueryData = (data: any) => {
    return queryClient.setQueryData(queryRef, data);
  };

  const lastPage = data?.pages?.length ? data.pages[data.pages.length - 1] : { data: { filters: {} } };

  const pages = useMemo(() => {
    return data?.pages?.length
      ? data.pages.reduce((acc: Recording[], page: any) => {
          return acc.concat(page.data.recordings);
        }, [])
      : [];
  }, [data?.pages]);

  return {
    data: pages,
    isError,
    isFetching,
    isFetchingNextPage,
    isSuccess,
    isPending,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    filters: lastPage.data.filters,
    setQueryData,
    fetchNextPage,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    totalElements: lastPage?.data?.page ? lastPage.data.page.totalElements : 0,
    setRecordingsFilters: setFilters,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    recordingsFilters: isEmpty(filters) ? lastPage.data.filters : filters,
    RecordingsStateContext: ({ children }: { children: ReactNode }) => (
      <RecordingsStateContext.Provider value={value}>{children}</RecordingsStateContext.Provider>
    ),
  };
};

const useRecordingsListFilters = () => {
  const context = React.useContext(RecordingsStateContext);
  if (context === undefined) {
    throw new Error('useRecordingsListFilters must be used within a RecordingsStateContext');
  }

  return context.filters;
};

export { useRecordings, useRecordingsListFilters };
