import { useAtomValue, useSetAtom } from 'jotai';
import Cookies from 'js-cookie';
import React, { useEffect } from 'react';

import { useSetUserAndClientIdForMetrics } from 'kognia/metrics/hooks/use-set-user-and-client-id-for-metrics';
import { APP_COLLECT_METRICS } from 'kognia/metrics/utils';
import { AppLoading } from 'shared/components/app-loading';
import {
  appState,
  currentClientIdAtom,
  featureFlagsState,
  isAuthorizedAtom,
  userAtom,
} from 'shared/contexts/app-state/atoms';
import { useAppData, UseAppDataType } from 'shared/contexts/app-state/hooks/useAppData';
import { useUnauthorize } from 'shared/contexts/app-state/hooks/useUnauthorize';
import usePageStateMachine, { PAGE_STATES } from 'shared/hooks/use-page-state-machine';
import { Client } from 'shared/types';
import { findClientOrThrow } from 'shared/utils/findClientOrThrow';
import { getBrandingFromClient } from 'shared/utils/getBrandingFromClient';
import { updateFavicon } from 'shared/utils/updateFavicon';

type AppStateProviderProps = { children: React.ReactNode };

const chooseInitialClientId = (userClients: Client[]) => {
  if (!userClients.length) {
    throw new Error('User has no environments');
  }

  const clientIdFromCookies = Cookies.get('current-client-id');
  if (clientIdFromCookies) {
    const client = userClients.find((client) => client.id === clientIdFromCookies);

    return client ? client.id : userClients[0].id;
  }

  return userClients[0].id;
};

export function AppStateProvider({ children }: AppStateProviderProps) {
  const setUser = useSetAtom(userAtom);
  const setUserAndClientIdForMetrics = useSetUserAndClientIdForMetrics();
  const setFeatureFlags = useSetAtom(featureFlagsState);
  const setIsAuthorized = useSetAtom(isAuthorizedAtom);
  const setCurrentClientId = useSetAtom(currentClientIdAtom);
  const setAppState = useSetAtom(appState);
  const currentClientId = useAtomValue(currentClientIdAtom);
  const logout = useUnauthorize();

  const { current, data, PAGE_STATES } = usePageStateMachine<UseAppDataType>(() =>
    useAppData(() => {
      logout();
    }),
  );

  useEffect(() => {
    if (current.matches(PAGE_STATES.READY) && data?.user && data?.featureFlags) {
      const initialCurrentClientId = chooseInitialClientId(data.user.clients);
      APP_COLLECT_METRICS && setUserAndClientIdForMetrics(initialCurrentClientId, data.user);

      if (currentClientId) {
        updateFavicon(getBrandingFromClient(findClientOrThrow(data.user.clients, initialCurrentClientId)).faviconUrl);
      }

      setUser(data.user);
      setCurrentClientId(initialCurrentClientId);
      setIsAuthorized(true);
      setFeatureFlags(data.featureFlags);
      setAppState(PAGE_STATES.READY);
    } else {
      setAppState(current.value as PAGE_STATES);
    }
  }, [
    setUserAndClientIdForMetrics,
    current.value,
    data?.user,
    data?.featureFlags,
    current,
    PAGE_STATES.READY,
    setUser,
    setIsAuthorized,
    setCurrentClientId,
    setFeatureFlags,
    setAppState,
    data,
    currentClientId,
  ]);

  const isAppSettled = current.matches(PAGE_STATES.READY) || current.matches(PAGE_STATES.ERROR);

  return (
    <>
      <AppLoading isLoaded={isAppSettled} />
      {children}
    </>
  );
}
