import { TypeOf } from 'zod';

import {
  createTeamFormSchema,
  editTeamFormSchema,
  TeamTypes,
} from 'pages/backoffice/pages/teams/components/team-form/form';

export enum TeamFormFieldsNames {
  name = 'name',
  teamImage = 'team_image',
  teamType = 'team_type',
  abbreviation = 'abbreviation',
  countryCode = 'country_code',
}

export interface CreateTeamForm {
  [TeamFormFieldsNames.name]: string;
  [TeamFormFieldsNames.teamImage]?: FileList;
  [TeamFormFieldsNames.teamType]: TeamTypes;
  [TeamFormFieldsNames.abbreviation]: string;
  [TeamFormFieldsNames.countryCode]?: string;
}

export type CreateTeamFormSchema = TypeOf<typeof createTeamFormSchema>;

export interface EditTeamForm {
  [TeamFormFieldsNames.name]: string;
  [TeamFormFieldsNames.teamImage]?: FileList;
  [TeamFormFieldsNames.teamType]: TeamTypes;
  [TeamFormFieldsNames.abbreviation]: string;
  [TeamFormFieldsNames.countryCode]?: string;
}

export type EditTeamFormSchema = TypeOf<typeof editTeamFormSchema>;
