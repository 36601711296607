import throttle from 'lodash/throttle';
import { useCallback, useEffect, useMemo } from 'react';

import { createActions } from 'shared/components/video-player/state/hooks/utils';
import { Send } from 'shared/components/video-player/state/types';
import { PlayerStateMachineContext } from 'shared/components/video-player/types';
import { checkIsFinishedPlayingCurrentPlaylistItem } from 'shared/components/video-player/util';
import { getIsFormTag } from 'shared/utils/is-form-tag';

const CONTROLS_THROTTLE_TIME = 10;

export const useGenerateVideoPlayerActions = (context: PlayerStateMachineContext, send: Send) => {
  const actions = useMemo(() => createActions(send), [send]);

  const throttlePlay = useMemo(() => throttle(actions.play, CONTROLS_THROTTLE_TIME), [actions]);

  const throttlePause = useMemo(
    () =>
      throttle(async () => {
        actions.pause();
      }, CONTROLS_THROTTLE_TIME),
    [actions],
  );

  const throttlePreviousPlaylistItem = useMemo(
    () =>
      throttle(async () => {
        actions.previousPlaylistItem();
      }, CONTROLS_THROTTLE_TIME),
    [actions],
  );

  const throttleNextPlaylistItem = useMemo(
    () =>
      throttle(async () => {
        actions.nextPlaylistItem();
      }, CONTROLS_THROTTLE_TIME),
    [actions],
  );

  const throttlePreviousVideoSource = useMemo(
    () =>
      throttle(async () => {
        actions.previousVideoSource();
      }, CONTROLS_THROTTLE_TIME),
    [actions],
  );

  const throttleNextVideoSource = useMemo(
    () =>
      throttle(async () => {
        actions.nextVideoSource();
      }, CONTROLS_THROTTLE_TIME),
    [actions],
  );

  const throttleRemovePlaylistItem = useMemo(
    () =>
      throttle(async (id: string) => {
        actions.removePlaylistItem(id);
      }, CONTROLS_THROTTLE_TIME),
    [actions],
  );

  const throttleTogglePlaying = useMemo(
    () =>
      throttle(async () => {
        checkIsFinishedPlayingCurrentPlaylistItem(context.playlist, context.videoRef)
          ? actions.setPlaylistItem(context.playlist.currentPlaylistItemId, true)
          : actions.togglePlaying();
      }, CONTROLS_THROTTLE_TIME),
    [actions, context],
  );

  const throttleSkipForward5s = useMemo(
    () =>
      throttle(async () => {
        actions.skipForward5s();
      }, CONTROLS_THROTTLE_TIME),
    [actions],
  );

  const throttleSkipBackward5s = useMemo(
    () =>
      throttle(async () => {
        actions.skipBackward5s();
      }, CONTROLS_THROTTLE_TIME),
    [actions],
  );

  const throttleJumpToTimeInMatch = useMemo(
    () =>
      throttle((time: number) => {
        actions.jumpToMatchTime(time);
      }, CONTROLS_THROTTLE_TIME),
    [actions],
  );

  const throttleJumpToTimePercentage = useMemo(
    () =>
      throttle((percent: number) => {
        actions.jumpToTimePercent(percent);
      }, CONTROLS_THROTTLE_TIME),
    [actions],
  );

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      const isFormTag = getIsFormTag((event.target as HTMLElement).tagName);
      if (isFormTag) return;

      if (event.key === ' ') throttleTogglePlaying();
      if (event.key === 'ArrowRight') {
        event.ctrlKey ? throttleNextVideoSource() : throttleSkipForward5s();
      }
      if (event.key === 'ArrowLeft') {
        event.ctrlKey ? throttlePreviousVideoSource() : throttleSkipBackward5s();
      }
    },
    [
      throttlePreviousVideoSource,
      throttleNextVideoSource,
      throttleSkipBackward5s,
      throttleSkipForward5s,
      throttleTogglePlaying,
    ],
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  return useMemo(
    () => ({
      ...actions,
      jumpToTimeInMatch: throttleJumpToTimeInMatch,
      jumpToTimePercent: throttleJumpToTimePercentage,
      nextPlaylistItem: throttleNextPlaylistItem,
      nextVideoSource: throttleNextVideoSource,
      pause: throttlePause,
      play: throttlePlay,
      previousPlaylistItem: throttlePreviousPlaylistItem,
      previousVideoSource: throttlePreviousVideoSource,
      removePlaylistItem: throttleRemovePlaylistItem,
    }),
    [
      actions,
      throttleJumpToTimeInMatch,
      throttleJumpToTimePercentage,
      throttleNextPlaylistItem,
      throttleNextVideoSource,
      throttlePause,
      throttlePlay,
      throttlePreviousPlaylistItem,
      throttlePreviousVideoSource,
      throttleRemovePlaylistItem,
    ],
  );
};
