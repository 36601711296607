import { UseQueryResult } from '@tanstack/react-query';

import { useFetchRequest } from 'api/hooks/useFetchRequest';
import { annotationApiUrls } from 'pages/backoffice/api/routes';
import { transformTeam } from 'pages/backoffice/api/teams/transformers';
import { TeamWithPlayers } from 'pages/backoffice/types/teams';

type Params = {
  teamId?: string;
  onSuccess?: (team: TeamWithPlayers) => void;
};

export const useTeam = ({ teamId, onSuccess }: Params): UseQueryResult<TeamWithPlayers> => {
  return useFetchRequest<TeamWithPlayers>({
    queryRef: ['metadata-team', teamId ?? ''],
    url: `${annotationApiUrls.TEAMS}/${teamId ?? ''}`,
    transformer: transformTeam,
    options: {
      enabled: teamId !== undefined,
    },
    onSuccess,
  });
};
