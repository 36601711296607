import { Box, MenuItem, Select, SelectChangeEvent, Stack, TextField, Typography } from '@mui/material';
import { Colors } from 'kognia-ui';
import map from 'lodash/map';
import throttle from 'lodash/throttle';
import { FocusEvent, useCallback, useMemo } from 'react';

import { PlayersMappings, usePlayersMappings } from 'pages/backoffice/api/use-players-mappings';
import {
  getJerseyNumber,
  getTeamLastGameDate,
  getTeamName,
} from 'pages/backoffice/pages/games/game-form/components/selected-players/utils';
import { MetadataPlayerSummary } from 'pages/backoffice/types/players';
import { IconRemove } from 'shared/components/icons/icon-remove';
import { PlayerPhoto } from 'shared/components/player-photo/PlayerPhoto';
import { useDates } from 'shared/hooks/use-dates';

type Props = {
  player: MetadataPlayerSummary;
  hasJerseyNumberError: boolean;
  removePlayers: (player: MetadataPlayerSummary[]) => void;
  onUpdatePlayer: (player: MetadataPlayerSummary) => void;
};

const isIndexSpecificPosition = (mappings: PlayersMappings | undefined, position: number | undefined, name: string) => {
  if (!mappings) return false;
  const index = map(mappings.positions, (m) => m).findIndex((p) => p === name);

  return index === position;
};

const getSideValue = (mappings: PlayersMappings, name: string) => {
  const found = map(mappings.sides, (m, idx) => ({ name: m, value: Number(idx) })).find((p) => p.name === name);

  return found?.value;
};

const getPlayerSide = (mappings: PlayersMappings | undefined, position: number, player: MetadataPlayerSummary) => {
  if (!mappings) return player.side;
  if (isIndexSpecificPosition(mappings, position, 'Goalkeeper')) return getSideValue(mappings, 'Center') ?? player.side;
  if (isIndexSpecificPosition(mappings, position, 'Unknown')) return getSideValue(mappings, 'Unknown') ?? player.side;

  return player.side;
};

export const SelectedPlayerItem = ({ player, removePlayers, onUpdatePlayer, hasJerseyNumberError }: Props) => {
  const { data: mappings } = usePlayersMappings();
  const { dateToString } = useDates();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSelectPosition = useCallback(
    throttle((event: SelectChangeEvent<number>) => {
      const position = Number(event.target.value);
      onUpdatePlayer({ ...player, position, side: getPlayerSide(mappings, position, player) });
    }, 200),
    [player, onUpdatePlayer],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSelectSide = useCallback(
    throttle((event: SelectChangeEvent<number>) => {
      const side = Number(event.target.value);
      onUpdatePlayer({ ...player, side });
    }, 200),
    [player, onUpdatePlayer],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleOnBlurJerseyNumber = useCallback(
    throttle((event: FocusEvent<HTMLInputElement>) => {
      const jerseyNumber = parseInt(event.target.value);
      onUpdatePlayer({ ...player, jerseyNumber });
    }, 200),
    [player, onUpdatePlayer],
  );

  const playersPositions = useMemo(
    () =>
      map(mappings?.positions ?? [], (position, value) => {
        return (
          <MenuItem key={position} value={value}>
            {position} ({value})
          </MenuItem>
        );
      }),
    [mappings],
  );

  const playersSides = useMemo(
    () =>
      map(mappings?.sides ?? [], (side, value) => {
        return (
          <MenuItem key={side} value={value}>
            {side} ({value})
          </MenuItem>
        );
      }),
    [mappings],
  );

  const jerseyNumber = getJerseyNumber(player);
  const teamName = getTeamName(player);
  const lastGameDate = getTeamLastGameDate(player);
  const formattedLastGameDate = lastGameDate ? dateToString(lastGameDate) : undefined;
  const position = player.position ?? undefined;
  const side = player.side ?? undefined;
  const isGoalkeeperPosition = isIndexSpecificPosition(mappings, position, 'Goalkeeper');
  const isUnknownPosition = isIndexSpecificPosition(mappings, position, 'Unknown');

  return (
    <Box
      sx={{
        borderBottom: `1px solid ${Colors.athens}`,
        padding: 1,
        display: 'grid',
        alignItems: 'center',
        gridTemplateColumns: '32px 1.5fr 170px 170px 100px 2fr',
      }}
    >
      <IconRemove color='secondary' size='small' isButton onClick={() => removePlayers([player])} />
      <Stack direction={'row'} alignItems={'center'} gap={1}>
        <PlayerPhoto src={player.photoUrl} />
        <Typography>{player.name}</Typography>
      </Stack>
      <Select
        variant='outlined'
        name={`${player.id}-position`}
        id={`${player.id}-position`}
        size={'small'}
        sx={{ width: '150px' }}
        value={position}
        onChange={handleSelectPosition}
      >
        {playersPositions}
      </Select>
      {!isGoalkeeperPosition && !isUnknownPosition ? (
        <Select
          variant='outlined'
          name={`${player.id}-side`}
          key={player.id}
          id={`${player.id}-side`}
          size={'small'}
          sx={{ width: '150px' }}
          value={side}
          onChange={handleSelectSide}
        >
          {playersSides}
        </Select>
      ) : (
        <span />
      )}
      <TextField
        size='small'
        fullWidth
        type='number'
        error={hasJerseyNumberError}
        value={jerseyNumber}
        placeholder={`#${jerseyNumber}`}
        onChange={handleOnBlurJerseyNumber}
        sx={{ width: '80px' }}
      />
      {teamName && formattedLastGameDate ? (
        <Typography sx={{ wrap: 'no-wrap' }}>
          {teamName} / {formattedLastGameDate}
        </Typography>
      ) : null}
    </Box>
  );
};
