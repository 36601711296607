import account from 'kognia/i18n/locales/en-US/account.json';
import api from 'kognia/i18n/locales/en-US/api.json';
import common from 'kognia/i18n/locales/en-US/common.json';
import createTeam from 'kognia/i18n/locales/en-US/create-team.json';
import descriptions from 'kognia/i18n/locales/en-US/descriptions.json';
import downloadManager from 'kognia/i18n/locales/en-US/download-manager.json';
import explore from 'kognia/i18n/locales/en-US/explore.json';
import faqs from 'kognia/i18n/locales/en-US/faqs.json';
import filters from 'kognia/i18n/locales/en-US/filters.json';
import forms from 'kognia/i18n/locales/en-US/forms.json';
import fundamentals from 'kognia/i18n/locales/en-US/fundamentals.json';
import home from 'kognia/i18n/locales/en-US/home.json';
import languages from 'kognia/i18n/locales/en-US/languages.json';
import playlistDetail from 'kognia/i18n/locales/en-US/playlist-detail.json';
import playlists from 'kognia/i18n/locales/en-US/playlists.json';
import recordingEdit from 'kognia/i18n/locales/en-US/recording-edit.json';
import recordingPlaylists from 'kognia/i18n/locales/en-US/recording-playlists.json';
import recording from 'kognia/i18n/locales/en-US/recording.json';
import recordingsList from 'kognia/i18n/locales/en-US/recordings-list.json';
import recordings from 'kognia/i18n/locales/en-US/recordings.json';
import sharePlaylistModal from 'kognia/i18n/locales/en-US/share-playlist-modal.json';
import switchEnvironment from 'kognia/i18n/locales/en-US/switch-environment.json';
import taggingTool from 'kognia/i18n/locales/en-US/tagging-tool.json';
import timeline from 'kognia/i18n/locales/en-US/timeline.json';
import tutorials from 'kognia/i18n/locales/en-US/tutorials.json';
import videoPlayer from 'kognia/i18n/locales/en-US/video-player.json';
import { Namespaces } from 'kognia/i18n/types';

const translations = {
  [Namespaces['ACCOUNT']]: account,
  [Namespaces['API']]: api,
  [Namespaces['COMMON']]: common,
  [Namespaces['CREATE-TEAM']]: createTeam,
  [Namespaces['DESCRIPTIONS']]: descriptions,
  [Namespaces['FORMS']]: forms,
  [Namespaces['FUNDAMENTALS']]: fundamentals,
  [Namespaces['HOME']]: home,
  [Namespaces['LANGUAGES']]: languages,
  [Namespaces['PLAYLIST-DETAIL']]: playlistDetail,
  [Namespaces['PLAYLISTS']]: playlists,
  [Namespaces['RECORDING']]: recording,
  [Namespaces['RECORDING-EDIT']]: recordingEdit,
  [Namespaces['RECORDING-LIST']]: recordingsList,
  [Namespaces['RECORDING-PLAYLISTS']]: recordingPlaylists,
  [Namespaces['RECORDINGS']]: recordings,
  [Namespaces['SHARE-PLAYLIST-MODAL']]: sharePlaylistModal,
  [Namespaces['SWITCH_ENVIRONMENT']]: switchEnvironment,
  [Namespaces['TAGGING-TOOL']]: taggingTool,
  [Namespaces['TIMELINE']]: timeline,
  [Namespaces['TUTORIALS']]: tutorials,
  [Namespaces['VIDEO-PLAYER']]: videoPlayer,
  [Namespaces['FAQS']]: faqs,
  [Namespaces['FILTERS']]: filters,
  [Namespaces['DOWNLOAD_MANAGER']]: downloadManager,
  [Namespaces['EXPLORE']]: explore,
};

export default translations;
