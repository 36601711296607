import { TextField, Typography } from '@mui/material';
import debounce from 'lodash/debounce';
import { useEffect, useMemo, useState } from 'react';

import { emailSchema } from 'pages/backoffice/widgets/users/users-list/ui/emails-input/utils/emailsSchema';

interface Props {
  setEmails: (emails: string[]) => void;
}

const AVAILABLE_EMAILS_SEPARATORS_REGEXP = /[\s,\n]+/; // space, comma, line break
const DEBOUNCE_TIME = 300;

export const EmailsInput = ({ setEmails }: Props) => {
  const [errors, setErrors] = useState<string[]>([]);

  const handleChange = useMemo(
    () =>
      debounce((event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const inputValue = event.target.value;

        if (!inputValue.trim().length) {
          setEmails([]);
          setErrors([]);
        } else {
          const newErrors: string[] = [];
          const emailsList = inputValue
            .trim()
            .split(AVAILABLE_EMAILS_SEPARATORS_REGEXP)
            .filter((email) => email.trim() !== '');

          emailsList.forEach((email) => {
            try {
              emailSchema.parse(email);
            } catch {
              newErrors.push(email);
            }
          });

          setErrors(newErrors);

          if (!newErrors.length) {
            setEmails(emailsList);
          }
        }
      }, DEBOUNCE_TIME),
    [setEmails],
  );

  useEffect(() => {
    return () => handleChange.cancel();
  }, [handleChange]);

  return (
    <TextField
      onChange={handleChange}
      size='small'
      fullWidth
      multiline
      maxRows={5}
      placeholder='Lists of emails'
      helperText={
        errors.length ? (
          <Typography
            component='span'
            variant='inherit'
            overflow='hidden'
            textOverflow='ellipsis'
            whiteSpace='pre-wrap'
          >{`Not valid email(s): ${errors.join(', ')}`}</Typography>
        ) : null
      }
      error={!!errors.length}
    />
  );
};
