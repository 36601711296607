import { MetadataPlayerSummary } from 'pages/backoffice/types/players';

const NO_FIXTURES_VALUE = 0;

export const getPlayerValueFromFixtures = (
  player: MetadataPlayerSummary,
  value: 'position' | 'jerseyNumber',
): number => {
  if (!player.fixtures || !player.fixtures.length) return NO_FIXTURES_VALUE;

  const positionCount: Record<string, number> = {};

  player.fixtures.forEach((fixture) => {
    const key = `${fixture[value]}`;
    positionCount[key] = (positionCount[key] || 0) + 1;
  });

  const maxKey = Object.keys(positionCount).reduce(
    (max, current) => (positionCount[max] > positionCount[current] ? max : current),
    '',
  );

  return Number(maxKey);
};
