import { InfinityQueryDataResult } from 'api/hooks/useInfinityQuery/types';
import { MetadataPlayerApiResponse, PlayersApiResponse } from 'pages/backoffice/api/players/use-players/types';
import { transformStageSummaries } from 'pages/backoffice/api/stages/use-stages/transformer';
import { MetadataPlayerSummary } from 'pages/backoffice/types/players';
import { chooseNextCursorValue } from 'shared/utils/choose-next-cursor-value';

export const transformPlayer = (player: MetadataPlayerApiResponse): MetadataPlayerSummary => {
  return {
    id: player.uuid,
    name: player.name,
    photoUrl: player.photo_url,
    fixtures: player.fixtures.map((fixture) => ({
      jerseyNumber: fixture.jersey_number,
      side: fixture.side,
      name: fixture.name,
      date: fixture.date,
      position: fixture.position,
      id: fixture.uuid,
      homeTeamScore: fixture.home_team_score,
      awayTeamScore: fixture.away_team_score,
      team: {
        name: fixture.team.name,
        abbreviation: fixture.team.abbreviation,
        logoUrl: fixture.team.logo_url,
        teamType: fixture.team.team_type,
        country: fixture.team.country_code,
        id: fixture.team.uuid,
      },
    })),
    ...(!player.player_defaults
      ? undefined
      : {
          playerDefaults: {
            team: {
              name: player.player_defaults.team.name,
              abbreviation: player.player_defaults.team.abbreviation,
              logoUrl: player.player_defaults.team.logo_url,
              teamType: player.player_defaults.team.team_type,
              country: player.player_defaults.team.country_code,
              id: player.player_defaults.team.uuid,
            },
            season: {
              id: player.player_defaults.season.uuid,
              year: player.player_defaults.season.year,
              crossesYear: player.player_defaults.season.crosses_year,
              competition: {
                id: player.player_defaults.season.competition.uuid,
                name: player.player_defaults.season.competition.name,
                country: player.player_defaults.season.competition.country,
              },
              stages: transformStageSummaries(player.player_defaults.season.stages),
            },
          },
        }),
    mappings: player.mappings,
  };
};

export const transformPlayers = (players: MetadataPlayerApiResponse[]): MetadataPlayerSummary[] => {
  return players.map(transformPlayer);
};

export const transformPlayersResponse = (
  response: PlayersApiResponse,
): InfinityQueryDataResult<MetadataPlayerSummary> => {
  return {
    data: {
      items: transformPlayers(response.data),
      page: response.page,
    },
    nextCursor: chooseNextCursorValue(response.page.totalPages, response.page.number),
  };
};
