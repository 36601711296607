import { z } from 'zod';

import { PlayerFormFieldsNames } from 'pages/backoffice/pages/players/components/player-form/types';
import { playerMappingSchema } from 'pages/backoffice/types/players';

export const createPlayerFormSchema = z.object({
  [PlayerFormFieldsNames.name]: z.string().trim().min(2),
  [PlayerFormFieldsNames.playerImage]: z.instanceof(FileList).optional(),
});

export const editPlayerFormSchema = z.object({
  [PlayerFormFieldsNames.name]: z.string().trim().min(2),
  [PlayerFormFieldsNames.playerImage]: z.instanceof(FileList).optional(),
  [PlayerFormFieldsNames.mappings]: z.array(playerMappingSchema).optional(),
});
