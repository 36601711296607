import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import debounce from 'lodash/debounce';
import { ChangeEvent, useCallback, useState } from 'react';

import { usePlayers } from 'pages/backoffice/api/players/use-players';
import { BackofficeContentContainer } from 'pages/backoffice/components/backoffice-content-container';
import { CreatePlayerModal } from 'pages/backoffice/components/create-player-modal';
import { PlayersList } from 'pages/backoffice/pages/players/components/players-list';
import { PlayersListSkeleton } from 'pages/backoffice/pages/players/components/players-list-skeleton';
import IconPlus from 'shared/components/icons/icon-plus';
import { ListHeader } from 'shared/components/list/list-header';
import NotFound from 'shared/components/not-found';
import Pagination from 'shared/components/pagination';

const DEBOUNCE_TIME = 300;

export const Players = () => {
  const [isCreatePlayerModalOpen, setIsCreatePlayerModalOpen] = useState(false);
  const { items, page, fetchNextPage, isPending, isLoading, setFilters } = usePlayers();

  const handleCreatePlayerModalOpen = () => setIsCreatePlayerModalOpen(true);
  const handleCreatePlayerModalClose = () => setIsCreatePlayerModalOpen(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearchChange = useCallback(
    debounce((event: ChangeEvent<HTMLInputElement>) => {
      setFilters({ name: event.target.value });
    }, DEBOUNCE_TIME),
    [setFilters],
  );

  return (
    <BackofficeContentContainer>
      <Stack direction='row' alignItems='center' justifyContent='space-between' marginBottom={2}>
        <Typography variant='h4'>Players</Typography>
        <Button
          variant='contained'
          size='large'
          startIcon={<IconPlus size='small' sx={{ color: 'common.white' }} />}
          onClick={handleCreatePlayerModalOpen}
        >
          New player
        </Button>
      </Stack>

      <Stack marginBottom={2} justifyContent='flex-end' direction='row'>
        <TextField size='small' placeholder='Search players' onChange={handleSearchChange} />
      </Stack>

      <ListHeader>Player name</ListHeader>
      {!isPending && items.length === 0 ? (
        <Box marginTop={4}>
          <NotFound header={'No results'} />
        </Box>
      ) : null}
      {isPending ? <PlayersListSkeleton /> : <PlayersList players={items} />}
      {page ? (
        <Pagination
          total={page.totalElements}
          displayed={items.length}
          onShowMore={fetchNextPage}
          loading={isPending || isLoading}
          getStatsText={(displayed, total) => `${displayed} of ${total} players`}
        />
      ) : null}
      {isCreatePlayerModalOpen ? (
        <CreatePlayerModal open={isCreatePlayerModalOpen} onClose={handleCreatePlayerModalClose} />
      ) : null}
    </BackofficeContentContainer>
  );
};
