import { zodResolver } from '@hookform/resolvers/zod';
import React, { useCallback, useRef } from 'react';
import { useForm } from 'react-hook-form';

import { useCreatePlayer } from 'pages/backoffice/api/players/use-create-player';
import { useInvalidatePlayers } from 'pages/backoffice/api/players/use-players';
import { PlayerForm } from 'pages/backoffice/pages/players/components/player-form';
import { createPlayerFormSchema } from 'pages/backoffice/pages/players/components/player-form/form';
import {
  CreatePlayerForm,
  CreatePlayerFormSchema,
  PlayerFormFieldsNames,
} from 'pages/backoffice/pages/players/components/player-form/types';
import { PlayerPhotoPreview } from 'pages/backoffice/pages/players/components/player-photo-preview';
import { MetadataPlayerSummary } from 'pages/backoffice/types/players';
import { DialogNew } from 'shared/components/dialog-new';
import { IconUser } from 'shared/components/icons/icon-user';

interface Props {
  open: boolean;
  onClose: () => void;
  onSuccess?: (player: MetadataPlayerSummary) => void;
}

export const CreatePlayerModal = ({ open, onClose, onSuccess }: Props) => {
  const formRef = useRef<HTMLFormElement>(null);
  const invalidatePlayers = useInvalidatePlayers();
  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    trigger,
  } = useForm<CreatePlayerFormSchema>({
    resolver: zodResolver(createPlayerFormSchema),
  });

  const playerImage = watch(PlayerFormFieldsNames.playerImage);
  const photoUrlValue = playerImage && playerImage.length > 0 ? URL.createObjectURL(playerImage[0]) : undefined;

  const { createPlayer } = useCreatePlayer();

  const handleCreatePlayer = useCallback(
    (player: CreatePlayerForm) => {
      createPlayer({
        data: {
          name: player[PlayerFormFieldsNames.name],
          image: player[PlayerFormFieldsNames.playerImage] ? player[PlayerFormFieldsNames.playerImage][0] : undefined,
        },
        onSuccess: (player: MetadataPlayerSummary) => {
          onSuccess && onSuccess(player);
          invalidatePlayers().then(() => onClose());
        },
      });
    },
    [invalidatePlayers, onSuccess, onClose, createPlayer],
  );

  const handleModalSubmit = useCallback(() => {
    formRef.current?.requestSubmit();
  }, []);

  return (
    <DialogNew
      maxWidth='sm'
      fullWidth
      title='New player'
      icon={<IconUser size='small' />}
      onCancel={onClose}
      onClose={onClose}
      onSubmit={handleModalSubmit}
      buttonSubmitText={'Create player'}
      open={open}
    >
      <form ref={formRef} onSubmit={handleSubmit(handleCreatePlayer)}>
        <PlayerForm<CreatePlayerFormSchema> errors={errors} register={register} trigger={trigger} />
        <PlayerPhotoPreview photoUrl={photoUrlValue} />
      </form>
    </DialogNew>
  );
};
