import { Box, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { PlayerSearchFeature } from 'features/player-profile/player-search/PlayerSearch.feature';
import bgImage from 'shared/assets/bg-radar.webp';
import Container from 'shared/components/container';

export const ExplorePage = () => {
  const { t } = useTranslation('explore');

  return (
    <Box
      sx={(theme) => ({
        width: '100%',
        height: '100%',
        backgroundColor: theme.palette.common.white,
        backgroundImage: `url(${bgImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: `center top`,
        backgroundSize: 'cover',
      })}
    >
      <Container>
        <Stack gap={4} alignItems='center' pt={9}>
          <Stack gap={2} alignItems='center'>
            {/* TODO: replace with PageTitle and PageSubtitle after applying new typography */}
            <Typography component='h1' fontSize='32px' lineHeight='125%'>
              {t('explore:title')}
            </Typography>
            <Typography component='h2' fontSize='22px' lineHeight='127.273%' textAlign='center' color='primary'>
              {t('explore:description')}
            </Typography>
          </Stack>

          <PlayerSearchFeature size='medium' width={456} />
        </Stack>
      </Container>
    </Box>
  );
};
