import { MetadataGame, MetadataGamePlayer } from 'pages/backoffice/types/games';
import { MetadataPlayerSummary } from 'pages/backoffice/types/players';
import { TeamWithPlayers } from 'pages/backoffice/types/teams';

export const transformGamePlayer = (
  player: MetadataGamePlayer,
  team: TeamWithPlayers,
  game: MetadataGame,
): MetadataPlayerSummary => {
  return {
    id: player.id,
    name: player.name,
    photoUrl: player.photoUrl,
    fixtures: [
      {
        jerseyNumber: player.jerseyNumber,
        side: player.side,
        name: player.name,
        date: game.date ?? '',
        position: player.position,
        id: player.id,
        homeTeamScore: game.homeTeamScore,
        awayTeamScore: game.awayTeamScore,
        team,
      },
    ],
  };
};
