import { useCallback, useState } from 'react';

export const useModalState = () => {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

  const openModal = useCallback(() => setIsOpenModal(true), []);
  const closeModal = useCallback(() => setIsOpenModal(false), []);

  return { isOpenModal, openModal, closeModal, setIsOpenModal };
};
