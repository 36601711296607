import has from 'lodash/has';
import queryString from 'query-string';

import { RecordingAnnotationTypes, RecordingFilters } from 'pages/recordings-list/types';

export const getFiltersFromUrl = () => {
  const searchParams = queryString.parse(window.location.search); // TODO: use ROUTER

  const annotationType = has(searchParams, RecordingFilters.ANNOTATION_TYPE)
    ? (searchParams[RecordingFilters.ANNOTATION_TYPE] as string)
    : '';
  const competition = has(searchParams, RecordingFilters.COMPETITION)
    ? (searchParams[RecordingFilters.COMPETITION] as string[])
    : [];
  const date = has(searchParams, RecordingFilters.DATE) ? (searchParams[RecordingFilters.DATE] as string[]) : [];
  const matchday = has(searchParams, RecordingFilters.MATCHDAY)
    ? (searchParams[RecordingFilters.MATCHDAY] as string[])
    : [];
  const teamId = has(searchParams, RecordingFilters.TEAM) ? (searchParams[RecordingFilters.TEAM] as string[]) : [];
  const type = has(searchParams, RecordingFilters.TYPE) ? (searchParams[RecordingFilters.TYPE] as string[]) : [];

  return {
    competition: `${competition}`,
    date: `${date}`,
    matchday: `${matchday}`,
    teamId: `${teamId}`,
    type: `${type}`,
    ...(annotationType == RecordingAnnotationTypes.all ? {} : { annotationType }),
  };
};
