export enum MatchEndType {
  NORMAL_TIME = 'Normal Time',
  EXTENDED_TIME = 'Extended Time',
  GOLDEN_GOAL_RULE = 'Golden Goal Rule',
  PENALTY_SHOOT_OUT = 'Penalty Shoot-Out',
}

export const MappingProvider = {
  OPTA: 'opta',
} as const;
