import {
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import Alert from '@mui/material/Alert';
import { Fragment } from 'react';

import { usePlayersMappings } from 'pages/backoffice/api/use-players-mappings';
import { PlayerFixture, PlayerMapping } from 'pages/backoffice/types/players';
import { DialogNew } from 'shared/components/dialog-new';
import { IconUser } from 'shared/components/icons/icon-user';
import Spinner from 'shared/components/spinner';
import { useDates } from 'shared/hooks/use-dates';

interface Props {
  onClose: () => void;
  playerFixtures: PlayerFixture[];
  playerMappings?: PlayerMapping[];
}

function createData(name: string, value: string) {
  return { name, value };
}

const TABLE_WIDTH = 650;

const FixturesTable = ({ playerFixture }: { playerFixture: PlayerFixture }) => {
  const { isLoading, data } = usePlayersMappings();
  const { dateToString } = useDates();

  if (isLoading || !data) return <Spinner isFullPage />;

  const rows = [
    createData('Fixture', playerFixture.name),
    createData('Date', dateToString(playerFixture.date)),
    createData('Score', `${playerFixture.homeTeamScore} - ${playerFixture.awayTeamScore}`),
    createData('Jersey number', playerFixture.jerseyNumber.toString()),
    createData('Side', `${playerFixture.side ? data.sides[playerFixture.side] : ''}`),
    createData('Position', `${playerFixture.position ? data.positions[playerFixture.position] : ''}`),
    createData('Playing team', playerFixture.team.name),
    createData('Playing team Abbreviation', playerFixture.team.abbreviation),
    createData('Playing team country', playerFixture.team.country ?? ''),
    ...(playerFixture.mappings
      ? playerFixture.mappings.map((mapping) => createData(`mappings.${mapping.provider}`, mapping.player_id_provider))
      : []),
  ];

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: TABLE_WIDTH }} aria-label='Latest fixtures'>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell align='right'>Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component='th' scope='row'>
                {row.name}
              </TableCell>
              <TableCell align='right'>{row.value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const MappingsTable = ({ playerMappings }: { playerMappings: PlayerMapping[] }) => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: TABLE_WIDTH }} aria-label='Player mappings'>
        <TableHead>
          <TableRow>
            <TableCell>Provider</TableCell>
            <TableCell align='right'>Player provider id</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            {playerMappings.map((row) => (
              <Fragment key={row.provider}>
                <TableCell component='th' scope='row'>
                  {row.provider}
                </TableCell>
                <TableCell align='right'>{row.player_id_provider}</TableCell>
              </Fragment>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const PlayerDetailsModal = ({ onClose, playerFixtures, playerMappings = [] }: Props) => {
  return (
    <DialogNew
      fullWidth
      maxWidth='md'
      title={'Player details'}
      icon={<IconUser />}
      onCancel={onClose}
      onClose={onClose}
      open={true}
    >
      <Stack gap={2}>
        <Typography variant='h6'>Mappings</Typography>
        {playerMappings.length === 0 ? (
          <Alert variant={'outlined'} severity={'warning'} sx={{ display: 'flex', justifyContent: 'center' }}>
            No mapping data
          </Alert>
        ) : (
          <MappingsTable playerMappings={playerMappings} />
        )}
        <Typography variant='h6'>Latest fixtures</Typography>
        {playerFixtures.length === 0 ? (
          <Alert variant={'outlined'} severity={'warning'} sx={{ display: 'flex', justifyContent: 'center' }}>
            No fixtures data
          </Alert>
        ) : (
          playerFixtures.map((fixture) => <FixturesTable key={fixture.id} playerFixture={fixture} />)
        )}
      </Stack>
    </DialogNew>
  );
};
