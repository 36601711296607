import { Avatar, Box, Stack, Typography } from '@mui/material';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

type Props = React.HTMLAttributes<HTMLLIElement> & {
  index: number;
  isShowPastRequestsOptions: boolean;
  photoUrl: string | null | undefined;
  label: string;
};

export const PlayerSearchOption = forwardRef(
  (
    { onClick, index, isShowPastRequestsOptions, photoUrl, label, ...restProps }: Props,
    ref?: React.Ref<HTMLLIElement> | undefined,
  ) => {
    const { t } = useTranslation('explore');

    return (
      <>
        {index === 0 && (
          <Typography variant='caption' pl={2} fontWeight='fontWeightMedium'>
            {isShowPastRequestsOptions ? t('explore:input.recent-searches') : t('explore:input.search-results')}
          </Typography>
        )}
        <Box
          ref={ref}
          component='li'
          mt={index === 0 ? 1 : 0}
          sx={(theme) => ({
            '&:hover': {
              bgcolor: theme.palette.action.hover,
            },
          })}
          onClick={onClick}
          {...restProps}
        >
          <Stack direction='row' alignItems='center' gap={1}>
            <Avatar src={photoUrl || ''} alt='image' sx={{ width: 24, height: 24 }} />
            <Typography variant='body2' sx={{ wordBreak: 'break-word' }}>
              {label}
            </Typography>
          </Stack>
        </Box>
      </>
    );
  },
);

PlayerSearchOption.displayName = 'PlayerSearchOption';
