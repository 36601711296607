import { UseSuspenseQueryResult } from '@tanstack/react-query';

import { useFetchSuspenseRequest } from 'api/hooks/useFetchSuspenseRequest';
import { playerWithDefaults } from 'api/routes';
import { transformPlayer } from 'pages/backoffice/api/players/use-players/transformer';
import { MetadataPlayerSummary } from 'pages/backoffice/types/players';

const PLAYER_WITH_DEFAULTS_QUERY_KEY = 'player-with-defaults';

export const usePlayerWithDefaults = (playerId: string): UseSuspenseQueryResult<MetadataPlayerSummary> => {
  return useFetchSuspenseRequest<MetadataPlayerSummary>({
    url: playerWithDefaults(playerId),
    queryRef: [PLAYER_WITH_DEFAULTS_QUERY_KEY, playerId],
    transformer: transformPlayer,
  });
};
