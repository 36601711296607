import { useInfinityQuery } from 'api/hooks/useInfinityQuery';
import { InfinityQueryResult } from 'api/hooks/useInfinityQuery/types';
import { playerTransformer } from 'api/player/use-fetch-players/utils/playerTransformer';
import { generatePlayersUrl } from 'api/routes';
import { SortDirection } from 'shared/types/filters/types';
import { PlayerApiResponse, PlayersApiFilters, Player } from 'shared/types/player/player';

const FETCH_PLAYERS_QUERY_KEY = 'fetchPlayers';

export const useFetchPlayers = (): InfinityQueryResult<Player, PlayersApiFilters> => {
  return useInfinityQuery<Player, PlayersApiFilters, PlayerApiResponse>({
    generateUrl: generatePlayersUrl,
    key: FETCH_PLAYERS_QUERY_KEY,
    options: {
      size: 10,
      sort: 'name',
      sortDirection: SortDirection.DESC,
    },
    initialFilters: {
      name: '',
    },
    transformer: playerTransformer,
    queryOptions: {
      enabled: true,
    },
  });
};
