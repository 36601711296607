import { zodResolver } from '@hookform/resolvers/zod';
import { Stack } from '@mui/material';
import { forwardRef, useCallback } from 'react';
import { useForm } from 'react-hook-form';

import { useCreateUser } from 'api/backoffice/user/use-create-user';
import { CreateKogniaUser, KogniaUseLocale, kogniaUserLocales } from 'api/backoffice/user/use-kognia-users/types';
import { SelectClients } from 'pages/backoffice/components/select-clients';
import {
  CreateUserFormFieldsNames,
  createUserFormSchema,
} from 'pages/backoffice/features/users/create-user-form/utils/createUserFormShema';
import { FormInputField } from 'shared/components/form/form-input-field';
import { FormSelectField } from 'shared/components/form/form-select-field';
import { getTypeOptions } from 'shared/components/form/get-type-options';

export const CreateClientFormLabels = {
  [CreateUserFormFieldsNames.FIRST_NAME]: 'First name',
  [CreateUserFormFieldsNames.LAST_NAME]: 'Last name',
  [CreateUserFormFieldsNames.EMAIL]: 'Email',
  [CreateUserFormFieldsNames.LOCALE]: 'Locale',
  [CreateUserFormFieldsNames.CLIENT_IDS]: 'Environments',
};

const inputFormFields = [
  CreateUserFormFieldsNames.FIRST_NAME,
  CreateUserFormFieldsNames.LAST_NAME,
  CreateUserFormFieldsNames.EMAIL,
];

export const CreateUserFormFeature = forwardRef(({}, ref: React.LegacyRef<HTMLFormElement> | undefined) => {
  const { createUser } = useCreateUser();
  const {
    register,
    watch,
    setValue,
    handleSubmit: handleFormSubmit,
    formState: { errors },
  } = useForm<CreateKogniaUser>({
    defaultValues: {
      [CreateUserFormFieldsNames.CLIENT_IDS]: [],
      [CreateUserFormFieldsNames.LOCALE]: kogniaUserLocales[0] as KogniaUseLocale,
      [CreateUserFormFieldsNames.EMAIL]: '',
      [CreateUserFormFieldsNames.FIRST_NAME]: '',
      [CreateUserFormFieldsNames.LAST_NAME]: '',
    },
    resolver: zodResolver(createUserFormSchema),
  });

  const selectedClients = watch('clientIds');

  const handleCreateUser = useCallback(
    (data: CreateKogniaUser) => {
      createUser({
        data,
        onSuccess: () => {
          setValue(CreateUserFormFieldsNames.FIRST_NAME, '');
          setValue(CreateUserFormFieldsNames.LAST_NAME, '');
          setValue(CreateUserFormFieldsNames.EMAIL, '');
        },
      });
    },
    [createUser, setValue],
  );

  const setSelectedClients = useCallback(
    (clientIds: string[]) => {
      setValue(CreateUserFormFieldsNames.CLIENT_IDS, clientIds);
    },
    [setValue],
  );

  return (
    <form ref={ref} onSubmit={handleFormSubmit(handleCreateUser)}>
      <Stack direction='column' spacing={2}>
        {inputFormFields.map((field) => (
          <FormInputField
            key={field}
            id={field}
            label={CreateClientFormLabels[field]}
            error={!!errors[field]}
            fullWidth
            helperText={errors[field] && <>{errors[field]?.message}</>}
            {...register(field)}
          />
        ))}

        <FormSelectField
          id={CreateUserFormFieldsNames.LOCALE}
          label={CreateClientFormLabels[CreateUserFormFieldsNames.LOCALE]}
          options={getTypeOptions({
            options: kogniaUserLocales.map((locale) => ({
              value: locale,
              label: locale,
            })),
          })}
          error={!!errors[CreateUserFormFieldsNames.LOCALE]}
          helperText={
            errors[CreateUserFormFieldsNames.LOCALE] && <>{errors[CreateUserFormFieldsNames.LOCALE]?.message}</>
          }
          {...register(CreateUserFormFieldsNames.LOCALE)}
        />

        <SelectClients clientIds={selectedClients} onChange={setSelectedClients} />
      </Stack>
    </form>
  );
});

CreateUserFormFeature.displayName = 'CreateUserFormFeature';
