import { UseQueryResult } from '@tanstack/react-query';

import { useFetchRequest } from 'api/hooks/useFetchRequest';
import { annotationApiUrls } from 'pages/backoffice/api/routes';
import { PlayerPositions, PlayerSides } from 'pages/backoffice/types/players';

export type PlayersMappings = {
  positions: PlayerPositions;
  sides: PlayerSides;
};

export interface PlayerPositionOrSideApi {
  name: string;
  value: number;
}

type PlayersMappingsApi = {
  positions: PlayerPositionOrSideApi[];
  sides: PlayerPositionOrSideApi[];
};

const transformMappings = (response: PlayersMappingsApi): PlayersMappings => {
  return {
    positions: response.positions.reduce<PlayerPositions>(
      (acc, position) => ({
        ...acc,
        [position.value]: position.name,
      }),
      {},
    ),
    sides: response.sides.reduce<PlayerSides>((acc, side) => ({ ...acc, [side.value]: side.name }), {}),
  };
};

export const usePlayersMappings = (): UseQueryResult<PlayersMappings> => {
  return useFetchRequest<PlayersMappings>({
    queryRef: ['metadata-players-mappings'],
    url: annotationApiUrls.MAPPINGS,
    transformer: transformMappings,
    options: {
      staleTime: Infinity,
    },
  });
};
