import { MetadataPlayerSummary } from 'pages/backoffice/types/players';

export const getJerseyNumber = (player: MetadataPlayerSummary): number => {
  if (player.jerseyNumber) return player.jerseyNumber;

  return player.fixtures && player.fixtures.length > 0 ? player.fixtures[0].jerseyNumber : 0;
};
export const getTeamName = (player: MetadataPlayerSummary): string => {
  return player.fixtures && player.fixtures.length > 0 ? player.fixtures[0].team.name : '';
};
export const getTeamLastGameDate = (player: MetadataPlayerSummary): string | undefined => {
  return player.fixtures && player.fixtures.length > 0 ? player.fixtures[0].date : undefined;
};
