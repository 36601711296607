import { z } from 'zod';

import { SeasonSchema } from 'pages/backoffice/types/seasons';
import { MappingProvider } from 'pages/backoffice/types/shared';

export const playerMappingSchema = z.object({
  provider: z.nativeEnum(MappingProvider),
  player_id_provider: z.string().min(1),
});

export const playerMappingsSchema = z.array(playerMappingSchema);
export type PlayerMapping = z.TypeOf<typeof playerMappingSchema>;

export const playerFixtureTeamSchema = z.object({
  name: z.string(),
  abbreviation: z.string(),
  logoUrl: z.string().optional().nullable(),
  teamType: z.string(),
  country: z.string().optional().nullable(),
  id: z.string(),
});

export const playerFixtureSchema = z.object({
  jerseyNumber: z.number(),
  side: z.number().optional(),
  name: z.string(),
  date: z.string(),
  position: z.number().optional(),
  id: z.string(),
  homeTeamScore: z.number(),
  awayTeamScore: z.number(),
  team: playerFixtureTeamSchema,
  mappings: playerMappingsSchema.optional(),
});

export type PlayerFixture = z.TypeOf<typeof playerFixtureSchema>;

export const playerSummarySchema = z.object({
  id: z.string(),
  name: z.string(),
  photoUrl: z.string().optional().nullable(),
  fixtures: z.array(playerFixtureSchema).optional(),
  position: z.number().optional(),
  side: z.number().optional(),
  jerseyNumber: z.number().optional(),
  playerDefaults: z
    .object({
      team: playerFixtureTeamSchema,
      season: SeasonSchema,
    })
    .optional(),
  mappings: playerMappingsSchema.optional(),
});

export type MetadataPlayerSummary = z.TypeOf<typeof playerSummarySchema>;

export type MetadataPlayersApiFilters = {
  name?: string;
  sort?: string;
  sortDirection?: string;
};

export type PlayerPositions = { [key in number]: string };
export type PlayerSides = { [key in number]: string };
