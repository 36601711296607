import { SUPERSET_PLAYER_PROFILE_EXPLORE_DASHBOARD_ID } from 'config';
import { EmbeddedDashboardFeature } from 'features/dashboard/embedded-dashboard/EmbeddedDashboard.feature';
import { useDashboardEvents } from 'features/dashboard/embedded-dashboard/hooks/useDashboardEvents';
import { DashboardPlaylistGenericPayload } from 'features/dashboard/embedded-dashboard/types/embeddedDashboard.types';
import { MetadataPlayerSummary } from 'pages/backoffice/types/players';
import { invariant } from 'shared/utils/invariant';

type Props = {
  player: MetadataPlayerSummary;
  seasonYear?: number;
  competitionId?: string;
  onEvent: (payload: DashboardPlaylistGenericPayload) => void;
};

export const PlayerProfileDashboardWidget = ({ player, competitionId, seasonYear, onEvent }: Props) => {
  const season = player.playerDefaults?.season;
  invariant(SUPERSET_PLAYER_PROFILE_EXPLORE_DASHBOARD_ID, 'Player profile not found due to local configuration issue');
  invariant(season);
  invariant(season.competition);

  useDashboardEvents({ onEvent: onEvent });

  return (
    <EmbeddedDashboardFeature
      playerId={player.id}
      competitionId={competitionId}
      seasonYear={seasonYear}
      key={SUPERSET_PLAYER_PROFILE_EXPLORE_DASHBOARD_ID}
      dashboardId={SUPERSET_PLAYER_PROFILE_EXPLORE_DASHBOARD_ID}
    />
  );
};
