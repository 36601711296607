import { Box, Stack, TextField } from '@mui/material';
import debounce from 'lodash/debounce';
import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { INITIAL_USERS_FILTERS, useKogniaUsers } from 'api/backoffice/user/use-kognia-users';
import { KogniaUser } from 'api/backoffice/user/use-kognia-users/types';
import { ListSkeleton } from 'pages/backoffice/components/list-skeleton';
import { UsersListFeature } from 'pages/backoffice/features/users/users-list/UsersList.feature';
import { AddUsersToEnvironmentsModal } from 'pages/backoffice/widgets/users/users-list/ui/add-users-to-environments-modal/AddUsersToEnvironmentsModal';
import { DeleteUsersConfirmModal } from 'pages/backoffice/widgets/users/users-list/ui/delete-users-confirm-modal/DeleteUsersConfirmModal';
import { EmailsInput } from 'pages/backoffice/widgets/users/users-list/ui/emails-input/EmailsInput';
import { RemoveUsersFromEnvironmentsModal } from 'pages/backoffice/widgets/users/users-list/ui/remove-users-from-environments-modal/RemoveUsersFromEnvironmentsModal';
import { UserDetailsModal } from 'pages/backoffice/widgets/users/users-list/ui/user-details-modal/UserDetailsModal';
import NotFound from 'shared/components/not-found';
import Pagination from 'shared/components/pagination';
import { useModalState } from 'shared/hooks/use-modal-state/useModalState';

const DEBOUNCE_TIME = 300;
const MINIMAL_SEARCH_LENGTH = 2;

export const UsersListWidget = () => {
  const [pageSize, setPageSize] = useState<number | null>(null);
  const {
    items: users,
    page,
    isPending,
    isFetching,
    setFilters,
    fetchNextPage,
  } = useKogniaUsers({ initialFilters: INITIAL_USERS_FILTERS, pageSize });

  const [searchValue, setSearchValue] = useState<string>('');
  const searchInputRef = useRef<HTMLInputElement>(null);
  const [emails, setEmails] = useState<string[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [detailsUserData, setDetailsUserData] = useState<KogniaUser | null>(null);

  const {
    isOpenModal: isOpenAddUsersToEnvironmentsModal,
    openModal: handleOpenAddUsersToEnvironmentsModal,
    closeModal: handleCloseAddUsersToEnvironmentsModal,
  } = useModalState();
  const {
    isOpenModal: isOpenRemoveUsersFromEnvironmentsModal,
    openModal: handleOpenRemoveUsersFromEnvironmentsModal,
    closeModal: handleCloseRemoveUsersFromEnvironmentsModal,
  } = useModalState();
  const {
    isOpenModal: isOpenDeleteModal,
    openModal: handleOpenDeleteModal,
    closeModal: handleCloseDeleteModal,
  } = useModalState();

  useEffect(() => {
    setFilters({ firstName: searchValue.length >= MINIMAL_SEARCH_LENGTH ? searchValue : '', emails });
  }, [emails, searchValue, setFilters]);

  useEffect(() => {
    if (emails.length && !isFetching) {
      const selectedUsers = users.reduce<string[]>((acc, user) => {
        if (emails.includes(user.email)) {
          acc.push(user.id);
        }
        return acc;
      }, []);

      setSelectedUsers(selectedUsers);
    }
  }, [emails, isFetching, users]);

  const handleChangeSearchInput = useMemo(
    () =>
      debounce((event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setSearchValue(value);

        if (!emails.length) {
          setSelectedUsers([]);
        }
      }, DEBOUNCE_TIME),
    [emails.length],
  );

  useEffect(() => {
    return () => handleChangeSearchInput.cancel();
  }, [handleChangeSearchInput]);

  const handleSetEmails = useCallback((emails: string[]) => {
    const input = searchInputRef.current;
    if (input) {
      // eslint-disable-next-line react-compiler/react-compiler
      input.value = '';
    }

    if (!emails.length) {
      setSelectedUsers([]);
    }

    setSearchValue('');
    setPageSize(emails.length ? emails.length : null);
    setEmails(emails);
  }, []);

  const handleOpenDetailsModal = useCallback((user: KogniaUser) => {
    setDetailsUserData(user);
  }, []);

  const handleCloseDetailsModal = useCallback(() => {
    setDetailsUserData(null);
  }, []);

  const handleResetSelectedUsers = useCallback(() => {
    setSelectedUsers([]);
  }, []);

  return (
    <Box position='relative'>
      <Stack marginBottom={2} justifyContent='flex-end' direction='row'>
        <Stack direction='row' gap={2} width='100%' maxWidth={800}>
          <TextField
            inputRef={searchInputRef}
            fullWidth
            size='small'
            placeholder='Search by First Name'
            onChange={handleChangeSearchInput}
          />
          <EmailsInput setEmails={handleSetEmails} />
        </Stack>
      </Stack>

      {isPending ? (
        <ListSkeleton />
      ) : (
        <>
          {users.length ? (
            <>
              <UsersListFeature
                users={users}
                openDetailsModal={handleOpenDetailsModal}
                openDeleteModal={handleOpenDeleteModal}
                openAddUsersToEnvironmentsModal={handleOpenAddUsersToEnvironmentsModal}
                openRemoveUsersFromEnvironmentsModal={handleOpenRemoveUsersFromEnvironmentsModal}
                selectedUsers={selectedUsers}
                setSelectedUsers={setSelectedUsers}
              />
              {page && (
                <Pagination
                  total={page.totalElements}
                  displayed={users.length}
                  onShowMore={fetchNextPage}
                  getStatsText={(displayed, total) => `${displayed} of ${total} users`}
                />
              )}
            </>
          ) : (
            <Box marginTop={4}>
              <NotFound header={'No results'} />
            </Box>
          )}
        </>
      )}

      {Boolean(detailsUserData) && (
        <UserDetailsModal open={Boolean(detailsUserData)} user={detailsUserData!} onClose={handleCloseDetailsModal} />
      )}

      <AddUsersToEnvironmentsModal
        open={isOpenAddUsersToEnvironmentsModal}
        selectedUsers={users.filter((user) => selectedUsers.includes(user.id))}
        onClose={handleCloseAddUsersToEnvironmentsModal}
        onSuccess={handleResetSelectedUsers}
      />

      <RemoveUsersFromEnvironmentsModal
        open={isOpenRemoveUsersFromEnvironmentsModal}
        selectedUsers={users.filter((user) => selectedUsers.includes(user.id))}
        onClose={handleCloseRemoveUsersFromEnvironmentsModal}
        onSuccess={handleResetSelectedUsers}
      />

      <DeleteUsersConfirmModal
        isOpen={isOpenDeleteModal}
        onClose={handleCloseDeleteModal}
        users={users.filter((user) => selectedUsers.includes(user.id))}
        onSuccess={handleResetSelectedUsers}
      />
    </Box>
  );
};
