import { Grid } from '@mui/material';

import styles from 'pages/recordings-list/components/vod-recording-card/RecordingCard.module.scss';
import { RecordingTypes } from 'shared/types/recording/types';

type MatchDayInfoProps = {
  type?: RecordingTypes;
  matchDay?: string;
};

const MatchDayInfo = ({ type, matchDay }: MatchDayInfoProps) => {
  if (type !== RecordingTypes.GAME || !matchDay) return null;

  return (
    <Grid item xs={12} sm={'auto'} className={styles.recordingCardTag}>
      {matchDay}
    </Grid>
  );
};

export default MatchDayInfo;
