import { Player, PlayerApi, PlayerApiResponse } from 'shared/types/player/player';
import { chooseNextCursorValue } from 'shared/utils/choose-next-cursor-value';

const transformPlayer = (player: PlayerApi): Player => {
  return {
    id: player.uuid,
    name: player.name,
    photoUrl: player.photo_url,
    fixtures: player.fixtures.map((fixture) => ({
      id: fixture.uuid,
      name: fixture.name,
      homeTeamScore: fixture.home_team_score,
      awayTeamScore: fixture.away_team_score,
      homePenaltyScore: fixture.home_penalty_score,
      awayPenaltyScore: fixture.away_penalty_score,
      matchEndType: fixture.match_end_type,
      position: fixture.position,
      side: fixture.side,
      jerseyNumber: fixture.jersey_number,
      date: fixture.date,
      team: {
        id: fixture.team.uuid,
        name: fixture.team.name,
        abbreviation: fixture.team.abbreviation,
        logoUrl: fixture.team.logo_url,
        teamType: fixture.team.team_type,
        countryCode: fixture.team.country_code,
      },
    })),
    mappings: player.mappings.map(({ provider, player_id_provider }) => ({
      provider,
      playerIdProvider: player_id_provider,
    })),
  };
};

export const playerTransformer = (response: PlayerApiResponse) => {
  return {
    data: {
      items: response.content.map((player) => transformPlayer(player)),
      page: response.page,
    },
    nextCursor: chooseNextCursorValue(response.page.totalPages, response.page.number),
  };
};
