import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { useBackendApi, UseBackendApiParams } from 'api/hooks/useBackendApi';
import { HTTPMethod, UseApiFetchOptions } from 'api/types';

interface UseApiFetchInterface {
  errorMessage?: string;
  onError?: (error: any) => void;
  onSettled?: () => void;
  onSuccess?: (...args: any[]) => void;
  refetch?: () => void;
  queryRef: string[];
  successMessage?: string;
  transformer?: (response: any) => void;
  url: string;
  options?: UseApiFetchOptions;
  data?: UseBackendApiParams;
  method?: HTTPMethod.GET | HTTPMethod.POST;
  placeholderData?: (data: unknown) => unknown;
}

export const useFetchRequest = <T>({
  queryRef,
  url,
  onSuccess,
  onError,
  onSettled,
  options,
  transformer = (transformerData) => transformerData,
  placeholderData,
  errorMessage,
  successMessage,
  data,
  method = HTTPMethod.GET,
}: UseApiFetchInterface): UseQueryResult<T> => {
  return useQuery({
    queryKey: queryRef,
    queryFn: () => useBackendApi(url, method, transformer, data),
    ...options,
    placeholderData,
    meta: {
      errorMessage,
      successMessage,
      onError,
      onSuccess,
      onSettled,
    },
  });
};
