import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';

import { useFetchPlayers } from 'api/player/use-fetch-players/useFetchPlayers';
import {
  PlayerSearchData,
  PlayerSearchInput,
} from 'features/player-profile/player-search/ui/player-search-input/PlayerSearchInput';
import { routes } from 'kognia/router/routes';

interface Props {
  width?: number | `${number}${'px' | 'rem' | 'em' | '%' | 'vw'}` | 'auto';
  size?: 'medium' | 'small';
}

export const PlayerSearchFeature = ({ width, size = 'medium' }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState<string>('');
  // TODO: get from localStorage
  const [pastRequests] = useState<PlayerSearchData[]>([]);

  const { items, fetchNextPage, isFetching, setFilters } = useFetchPlayers();

  const options = useMemo(
    () => items?.map(({ name, id, photoUrl }) => ({ label: name, value: id, photoUrl })) || [],
    [items],
  );

  const handleSearch = useCallback(
    (value: string) => {
      setFilters({ name: value });
    },
    [setFilters],
  );

  const handleSelectPlayer = useCallback(
    (playerData: PlayerSearchData) => {
      navigate(generatePath(routes.PLAYER_PROFILE, { playerId: playerData.value }));
    },
    [navigate],
  );

  return (
    <PlayerSearchInput
      width={width}
      size={size}
      onSelect={handleSelectPlayer}
      onSearch={handleSearch}
      value={searchValue}
      onChange={setSearchValue}
      fetchNextPage={fetchNextPage}
      options={options}
      pastRequestsOptions={pastRequests}
      loading={isFetching}
      placeholder={t('explore:input.placeholder')}
    />
  );
};
