import { Paper } from '@mui/material';
import { boxShadows, Colors } from 'kognia-ui';

type Props = React.HTMLAttributes<HTMLElement>;

export const PlayerSearchPaper = ({ children, ...props }: Props) => {
  return (
    <Paper
      sx={{
        mt: 0.5,
        border: `1px solid ${Colors.ghost}`,
        boxShadow: boxShadows[3],
        borderRadius: 1,
      }}
      {...props}
    >
      {children}
    </Paper>
  );
};
