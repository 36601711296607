import { Grid } from '@mui/material';
import { TFunction } from 'i18next';

import styles from 'pages/recordings-list/components/vod-recording-card/RecordingCard.module.scss';
import { RecordingTypes } from 'shared/types/recording/types';

type CompetitionInfoProps = {
  type?: RecordingTypes;
  competitionName?: string;
  t: TFunction<'translation'>;
};

const CompetitionInfo = ({ type, competitionName, t }: CompetitionInfoProps) => {
  if (type === RecordingTypes.GAME && !competitionName) return null;

  return (
    <Grid item xs={12} sm={'auto'} className={styles.recordingCardTag}>
      {type === RecordingTypes.GAME && competitionName}
      {type === RecordingTypes.TRAINING && t('recordings-list:recording-card.training')}
    </Grid>
  );
};

export default CompetitionInfo;
