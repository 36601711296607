import { Autocomplete as MuiAutocomplete, AutocompleteProps, Stack, TextField } from '@mui/material';
import { fontSizes } from 'kognia-ui';
import debounce from 'lodash/debounce';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PlayerSearchOption } from 'features/player-profile/player-search/ui/player-search-input/ui/PlayerSearchOption';
import { PlayerSearchPaper } from 'features/player-profile/player-search/ui/player-search-input/ui/PlayerSearchPaper';
import IconClose from 'shared/components/icons/icon-close';
import IconSearch from 'shared/components/icons/icon-search';
import Spinner from 'shared/components/spinner';

const DEFAULT_DEBOUNCE_DELAY = 300;
const MINIMAL_SEARCH_LENGTH = 3;
const DEFAULT_INPUT_WIDTH = 400;
const MIN_INPUT_WIDTH = 304;
const DEFAULT_RESULTS_HEIGHT = 304;
const CONTAINER_SCROLL_PADDING = 8;

export type PlayerSearchData = { value: string; label: string; photoUrl: string | null | undefined };

interface Props<T extends PlayerSearchData>
  extends Pick<AutocompleteProps<T, false, false, false>, 'options' | 'loading'> {
  pastRequestsOptions: readonly T[];
  value: string;
  onSearch: (searchValue: string) => void; // NOTE: use memo function
  onChange: (value: string) => void;
  onSelect: (data: PlayerSearchData) => void;
  fetchNextPage: () => void;
  width?: number | `${number}${'px' | 'rem' | 'em' | '%' | 'vw'}` | 'auto';
  size?: 'medium' | 'small';
  placeholder?: string;
}

const SIZE = {
  small: '32px',
  medium: '40px',
};

export const PlayerSearchInput = <T extends PlayerSearchData>({
  value,
  options,
  pastRequestsOptions,
  width,
  size = 'medium',
  placeholder,
  onSearch,
  onChange,
  onSelect,
  fetchNextPage,
  ...restProps
}: Props<T>) => {
  const { t } = useTranslation('explore');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  // TODO: change to "true" after adding pastRequestsOptions
  const [isShowPastRequestsOptions, setIsShowPastRequestsOptions] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const currentOptions = isShowPastRequestsOptions ? pastRequestsOptions : options;

  const debouncedSearchCallback = useMemo(
    () =>
      debounce((value: string) => {
        onSearch(value);
      }, DEFAULT_DEBOUNCE_DELAY),
    [onSearch],
  );

  useEffect(() => () => debouncedSearchCallback.cancel(), [debouncedSearchCallback]);

  const handleInputChange = useCallback(
    (_: React.SyntheticEvent, value: string) => {
      onChange(value);
      if (value.length >= MINIMAL_SEARCH_LENGTH) {
        debouncedSearchCallback(value);
      } else {
        debouncedSearchCallback('');
      }

      // TODO: uncomment after adding pastRequestsOptions
      // setIsShowPastRequestsOptions(value.length ? false : true);
    },
    [debouncedSearchCallback, onChange],
  );

  const handleReset = useCallback(() => {
    onChange('');
    // TODO: uncomment after adding pastRequestsOptions
    // setIsShowPastRequestsOptions(true);
  }, [onChange]);

  const handleBlur = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleFocus = useCallback(() => {
    setIsOpen(true);
  }, []);

  const listProps = useMemo(
    () => ({
      style: {
        maxHeight: DEFAULT_RESULTS_HEIGHT,
      },
      onScroll: (event: React.UIEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => {
        const target = event.currentTarget;
        const isScrollBottom = target.offsetHeight + target.scrollTop + CONTAINER_SCROLL_PADDING >= target.scrollHeight;

        if (isScrollBottom) {
          fetchNextPage();
        }
      },
      role: 'list-box',
    }),
    [fetchNextPage],
  );

  return (
    <MuiAutocomplete
      open={isOpen}
      onBlur={handleBlur}
      onFocus={handleFocus}
      inputValue={value}
      onInputChange={handleInputChange}
      disablePortal
      options={currentOptions}
      ListboxProps={listProps}
      filterOptions={(filteredOptions, { inputValue }) => {
        return inputValue.length >= MINIMAL_SEARCH_LENGTH || !inputValue.length ? filteredOptions : [...options];
      }}
      sx={(theme) => ({
        maxWidth: width ?? DEFAULT_INPUT_WIDTH,
        minWidth: MIN_INPUT_WIDTH,
        '& .MuiFormControl-root': {
          '& .MuiInputBase-root': {
            padding: theme.spacing(1),
            height: SIZE[size],
          },
        },
        '& ~ .MuiAutocomplete-popper': {
          '& .MuiAutocomplete-noOptions': {
            fontSize: fontSizes.small,
            fontWeight: theme.typography.fontWeightMedium,
          },
        },
      })}
      getOptionLabel={(option) => option.label}
      PaperComponent={PlayerSearchPaper}
      noOptionsText={
        isShowPastRequestsOptions ? t('explore:input.no-recent-searches') : t('explore:input.no-results-found')
      }
      loadingText={
        <Stack flex={1} alignItems={'center'}>
          <Spinner />
        </Stack>
      }
      renderOption={(props, { photoUrl, label, value }, { index }) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { key, onClick, ...optionProps } = props;

        const handleClick = () => {
          onSelect({ value, label, photoUrl });
          inputRef.current?.blur();
          onChange('');
          setIsOpen(false);
        };

        return (
          <PlayerSearchOption
            key={key}
            index={index}
            label={label}
            photoUrl={photoUrl}
            isShowPastRequestsOptions={isShowPastRequestsOptions}
            onClick={handleClick}
            {...optionProps}
          />
        );
      }}
      fullWidth
      renderInput={(params) => (
        <TextField
          variant='outlined'
          inputRef={inputRef}
          {...params}
          InputProps={{
            ...params.InputProps,
            size: 'small',
            endAdornment: value ? (
              <IconClose isButton size='small' sx={{ color: 'text.primary' }} onClick={handleReset} />
            ) : (
              <IconSearch size='small' color='secondary' />
            ),
            placeholder,
            onKeyDown: (event) => event.stopPropagation(),
          }}
        />
      )}
      {...restProps}
    />
  );
};
