import { List } from '@mui/material';

import { PlayerItem } from 'pages/backoffice/pages/players/components/player-item';
import { MetadataPlayerSummary } from 'pages/backoffice/types/players';

interface Props {
  players: MetadataPlayerSummary[];
}

export const PlayersList = ({ players }: Props) => {
  const playersItems = players.map((player) => {
    return <PlayerItem key={player.id} player={player} />;
  });

  return <List>{playersItems}</List>;
};
