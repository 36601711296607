import { Checkbox, ListItem, Typography } from '@mui/material';
import { useCallback } from 'react';

import { KogniaUser } from 'api/backoffice/user/use-kognia-users/types';
import { IconTimeline } from 'shared/components/icons';
import KebabMenu, { MenuListOption } from 'shared/components/kebab-menu';
import { useDates } from 'shared/hooks/use-dates';

interface Props {
  user: KogniaUser;
  selectedUsers: string[];
  openDetailsModal: (user: KogniaUser) => void;
  setSelectedUsers: React.Dispatch<React.SetStateAction<string[]>>;
}

export const UsersListItem = ({ user, selectedUsers, openDetailsModal, setSelectedUsers }: Props) => {
  const { dateToString } = useDates();

  const handleOpenDetailsModal = () => {
    openDetailsModal(user);
  };

  const handleOnChangeCheckedUser = useCallback(
    (userId: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setSelectedUsers((prev) => {
        if (!event.target.checked) {
          return prev.filter((item) => item !== userId);
        }

        return [...prev, userId];
      });
    },
    [setSelectedUsers],
  );

  const options: MenuListOption[] = [
    {
      displayText: 'Details',
      icon: <IconTimeline size='small' color='secondary' />,
      onClick: handleOpenDetailsModal,
    },
  ];

  return (
    <ListItem
      sx={(theme) => ({
        display: 'grid',
        gridTemplateColumns: '42px repeat(4, minmax(100px, 1fr)) 32px',
        borderBottom: `1px solid ${theme.palette.secondary.light}`,
        padding: theme.spacing(1, 2),
      })}
    >
      <Checkbox checked={selectedUsers.includes(user.id)} onChange={handleOnChangeCheckedUser(user.id)} />

      <Typography pr={1} fontWeight='fontWeightMedium' overflow='hidden' textOverflow='ellipsis' whiteSpace='nowrap'>
        {user.firstName}
      </Typography>

      <Typography pr={1} overflow='hidden' textOverflow='ellipsis' whiteSpace='nowrap'>
        {user.lastName}
      </Typography>

      <Typography pr={1} overflow='hidden' textOverflow='ellipsis' whiteSpace='nowrap'>
        {dateToString(user.updatedAt)}
      </Typography>

      <Typography pr={1} overflow='hidden' textOverflow='ellipsis' whiteSpace='nowrap'>
        {user.email}
      </Typography>

      <KebabMenu options={options} />
    </ListItem>
  );
};
